import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';

import Layout from '../components/layouts';

const FeatureSection: React.SFC<{
  image: React.ReactNode;
  align: 'left' | 'right';
}> = ({ image, align, children }) => {
  const content = (
    <div className="h-100 d-flex flex-column justify-content-center">
      <div className="align-self-center">{children}</div>
    </div>
  );

  return (
    <Container className="container-feature mt-5 mb-5">
      <Row>
        <Col xs="12" sm="6">
          {align === 'left' && content}
          {align !== 'left' && image}
        </Col>
        <Col xs="12" sm="6">
          {align === 'right' && content}
          {align !== 'right' && image}
        </Col>
      </Row>
    </Container>
  );
};

const IndexPage: React.SFC = () => (
  <Layout title="AuDEc">
    <Container className="mb-5">
      <Row>
        <Col sm={{ span: 8, offset: 2 }}>
          <h1>AES67 AoIP Streaming</h1>
          <p className="text-center">
            Low latency synchronized audio streaming for Raspberry Pi
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src="https://via.placeholder.com/1280x650.png?text=hero" />
        </Col>
      </Row>
    </Container>

    <FeatureSection
      align="left"
      image={
        <img src="https://via.placeholder.com/700x930.png?text=placeholder" />
      }
    >
      <h2>Play from multiple devices together</h2>
      <hr className="separator" />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus
        nisi quis lectus feugiat tincidunt. Vestibulum commodo iaculis feugiat.
        Praesent eget facilisis velit.
      </p>
    </FeatureSection>

    <FeatureSection
      align="right"
      image={
        <img src="https://via.placeholder.com/700x930.png?text=placeholder" />
      }
    >
      <h2>Low latency playback</h2>
      <hr className="separator" />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus
        nisi quis lectus feugiat tincidunt. Vestibulum commodo iaculis feugiat.
        Praesent eget facilisis velit.
      </p>
    </FeatureSection>

    <FeatureSection
      align="left"
      image={
        <img src="https://via.placeholder.com/700x930.png?text=placeholder" />
      }
    >
      <h2>Monitor and log audio streams</h2>
      <hr className="separator" />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus
        nisi quis lectus feugiat tincidunt. Vestibulum commodo iaculis feugiat.
        Praesent eget facilisis velit.
      </p>
    </FeatureSection>

    <FeatureSection
      align="right"
      image={
        <img src="https://via.placeholder.com/700x930.png?text=placeholder" />
      }
    >
      <h2>Open source platform</h2>
      <hr className="separator" />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut faucibus
        nisi quis lectus feugiat tincidunt. Vestibulum commodo iaculis feugiat.
        Praesent eget facilisis velit.
      </p>
    </FeatureSection>

    <hr />

    {/* <Container>
      <Row>
        <Col>
          <h1>Subscribe for updates</h1>
          <Form inline={true} className="justify-content-center" method="/lists/<address>/members" action="POST">
            <FormControl type="email" placeholder="Email Address" className="mr-sm-2" name="address"/>
            <Button type="submit" variant="success">Subscribe</Button>
          </Form>
        </Col>
      </Row>
    </Container> */}
  </Layout>
);

export default IndexPage;
